<template>
    <van-cell :title="note.live.title" @click="handleDetail(note)">
        <template #default>
            <!--<van-icon class="action-icon" name="edit" @click="onEdit" />-->
            <van-icon class="action-icon" name="delete" @click.stop.self="onDelete">
                <van-action-sheet
                    @select="handleSelect"
                    :round="false"
                    v-model="show"
                    :actions="actions"
                    cancel-text="取消"
                    description="确定删除吗?"
                    @cancel="show = false"
                ></van-action-sheet>
            </van-icon>
        </template>
        <template #label>
            {{ note.live.author }} · {{ note.live.live_at | dateFormatter }}
        </template>
    </van-cell>
</template>

<script>
import { deleteNote } from '@/api/note'
import { dateFormat } from '@/utils/util'

export default {
    name: 'NoteItem',
    props: {
        note: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            show: false,
            loading: false
        }
    },
    filters: {
        dateFormatter (value) {
            if (!value) return ''
            const date = new Date(value.replace(/-/g, '/'))
            return dateFormat(date, 'yyyy-MM-dd hh:mm')
        }
    },
    computed: {
        actions () {
            return [
                {
                    name: '确定',
                    loading: this.loading
                }
            ]
        }
    },
    methods: {
        onDelete () {
            this.show = true
        },
        handleDetail (s) {
            this.$router.push({
                path: '/note/' + s.id
            })
        },
        onEdit () {
            this.$router.push({ path: `/note/${this.note.id}/edit` })
        },
        handleSelect () {
            this.loading = true
            deleteNote(this.note.id).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$emit('delete', this.note)
            }).finally(() => {
                this.show = false
                this.loading = false
            })
        }
    }
};
</script>

<style lang="less">
    .action-icon {
        margin-left: 5px;
    }
</style>
