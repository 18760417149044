<template>
    <div>
        <my-image src="https://file4.mogody.com/avatars/XdIQLSb7hysluigM.jpg" />
        <van-list
            class="sermon-list"
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            :error.sync="error"
            @load="onLoad"
            error-text="加载,点击重新加载"
            ref="list"
        >
            <note-item @delete="handleDelete" :key="data.id" v-for="data in dataset.data" :note="data"></note-item>
        </van-list>
    </div>
</template>

<script>
import { getNotes } from '@/api/note'
import NoteItem from './widgets/NoteItem'
import MyImage from '@/components/MyImage'

export default {
    components: { NoteItem, MyImage },
    name: 'NoteIndex',
    data () {
        return {
            params: {},
            listLoading: false,
            listFinished: false,
            error: false,
            dataset: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            }
        }
    },
    methods: {
        handleSearch (params = {}) {
            this.params = params
            this.onRefresh()
        },
        onRefresh () {
            this.dataset.data = []
            this.dataset.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        handleDelete (s) {
            this.dataset.data = this.dataset.data.filter(i => i.id !== s.id)
        },
        onLoad () {
            const params = Object.assign(this.params, {
                page: this.dataset.meta.current_page + 1
            })
            getNotes(params).then(res => {
                res.data.unshift(...this.dataset.data)
                this.dataset = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    },
};
</script>

<style lang="less">
    .sermon-list {
        .van-cell__title {
            flex-grow: 4;
        }
        .van-cell__value {
            flex-grow: 1;
        }
    }
</style>
